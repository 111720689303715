<template>
  <b-container style="margin-top: 80px">
    <div v-if="isSrc" class="mt-1 mb-4 text-center">
      <h4 class="display-3 font-weight-bold">
        การสมัครสมาชิกชมรมแดร์ประเทศไทยสำเร็จเรียบร้อย
      </h4>

      <p>
        ระบบจะทำการส่ง SMS ยืนยันการอนุมัติการสมัครสมาชิกชมรมแดร์ประเทศไทย
        ให้ท่านอีกครั้งเพื่อรับหมายเลขการสมัครสมาชิก
        เพื่อใช้ในการอัพโหลดหลักฐานการชำระเงิน
      </p>

      <p>
        ท่านสามารถดาวน์โหลด QR Code
        ข้างล่างเพื่อใช้ในการสแกนตรวจสอบรายละเอียดการสมัครสมาชิกได้ในภายหลัง
      </p>

      <p>ขอบคุณสำหรับการสมัครสมาชิกเพื่อเป็นส่วนหนึ่งของชมรมแดร์ประเทศไทย</p>
    </div>

    <h4 v-else class="display-3 mt-1 mb-4 font-weight-bold text-center">
      สมัครสมาชิกชมรม D.A.R.E. ประเทศไทย
    </h4>

    <page-content class="mb-4">
      <b-row v-if="isSrc">
        <div class="col-12 text-center">
          <!-- <vue-qr
            :logoSrc="logoSrc"
            :text="isSrc"
            :size="size"
            :callback="qrCallback"
          ></vue-qr> -->
          <vue-qr
            :text="isSrc"
            :size="size"
            :callback="qrCallback"
          ></vue-qr>
        </div>
        <div class="col-12 text-center mt-5">
          <b-button
            v-if="srcImgBase64"
            variant="primary"
            @click="downLoadPdfQrcode"
          >
            ดาวน์โหลด</b-button
          >
        </div>

        <generate-pdf
          ref="pdf"
          :item="{ number: `คิวอาร์โค้ดสมัครสมาชิกชมรม D.A.R.E ประเทศไทย.pdf` }"
          :format="format"
          :enable-download="enableDownload"
          :preview-modal="previewModal"
        ></generate-pdf>
      </b-row>

      <b-row v-else>
        <div class="col-12 py-3 text-right text-black-50">{{ dateNow }}</div>
        <div class="col-12 py-3">
          <h5>ตรวจสอบหมายเลขบัตรประชนชนสำหรับลงทะเบียน</h5>
          <div class="d-flex flex-row align-items-end flex-wrap">
            <div class="py-2 px-0 col-12 col-md-3">
              <input
                type="text"
                min="0"
                class="form-control"
                id="text-id-card"
                placeholder="x-xxxx-xxxxx-xx-x"
                v-model="form.id_card"
                v-mask="'#-####-#####-##-#'"
                @input="onInputIdCard"
              />
            </div>
            <div class="py-2 col-12 col-md-9">
              <b-button variant="primary" size="sm" @click="checkAccessRegister"
                >ตรวจสอบ</b-button
              >
            </div>
          </div>
        </div>

        <div class="col-md-4 col-12 py-2">
          <label class="font-weight-bold" for="rank">ยศ</label>
          <v-select
            :id="isSubmit ? (rank ? 'rank' : 'rq_rank') : 'rank'"
            v-model="rank"
            :options="ranks"
            :loading="isFetchingRank"
            :disabled="!canRegister"
          ></v-select>
          <small v-if="isSubmit && !rank" class="text-danger">
            กรุณาเลือกยศ
          </small>
        </div>

        <div class="py-2 col-12 col-md-4">
          <label for="text-first_name">ชื่อ</label>
          <b-form-input
            type="text"
            id="text-first_name"
            v-model="form.first_name"
            :state="isSubmit ? (form.first_name ? null : false) : null"
            :disabled="!canRegister"
          ></b-form-input>
          <b-form-invalid-feedback id="text-first_name-feedback">
            กรุณากรอกชื่อ
          </b-form-invalid-feedback>
        </div>

        <div class="py-2 col-12 col-md-4">
          <label for="text-last_name">นามสกุล</label>
          <b-form-input
            type="text"
            id="text-last_name"
            v-model="form.last_name"
            :state="isSubmit ? (form.last_name ? null : false) : null"
            :disabled="!canRegister"
          ></b-form-input>
          <b-form-invalid-feedback id="text-last_name-feedback">
            กรุณากรอกนามสกุล
          </b-form-invalid-feedback>
        </div>
        <div class="py-2 col-12 col-md-6">
          <label for="BDate">(วัน เดือน ปี เกิด)</label>
          <datepicker
            ref="datepicker"
            id="BDate"
            :input-class="
              isSubmit
                ? birthDate
                  ? 'form-control'
                  : 'form-control required-date'
                : 'form-control'
            "
            v-model="birthDate"
            :language="th"
            :format="formatBuddhistBirthDate"
            :disabled="!canRegister"
          ></datepicker>
          <small v-if="isSubmit && !birthDate" class="text-danger">
            กรุณาเลือก (วัน เดือน ปี เกิด)
          </small>
        </div>
        <div v-show="false" class="py-2 col-12 col-md-3">
          <label for="text-age">อายุ (ปี)</label>
          <b-form-input
            type="number"
            min="0"
            id="text-age"
            v-model="form.age"
            :disabled="true"
          ></b-form-input>
        </div>
        <div class="py-2 col-12 col-md-6">
          <label for="text-phone">เบอร์โทรศัพท์</label>
          <b-form-input
            id="text-phone"
            type="tel"
            name="tel"
            placeholder="0xx-xxx-xxxx"
            v-mask="'0##-###-####'"
            v-model="form.phone_number"
            :state="isSubmit ? (form.phone_number ? null : false) : null"
            :disabled="!canRegister"
          ></b-form-input>
          <b-form-invalid-feedback id="text-phone-feedback">
            กรุณากรอกเบอร์โทรศัพท์
          </b-form-invalid-feedback>
        </div>
        <div class="py-2 col-12 col-md-6">
          <label for="text-email">E-mail</label>
          <b-form-input
            v-model="form.email"
            type="email"
            id="text-email"
            :state="isSubmit ? (form.email ? null : false) : null"
            :disabled="!canRegister"
          ></b-form-input>
          <b-form-invalid-feedback id="text-email-feedback">
            กรุณากรอก E-mail
          </b-form-invalid-feedback>
        </div>
        <div class="py-2 col-12 col-md-6">
          <label for="text-workplace_name">สถานที่ทำงาน</label>
          <b-form-input
            type="text"
            min="0"
            id="text-workplace_name"
            v-model="form.workplace_name"
            :state="isSubmit ? (form.workplace_name ? null : false) : null"
            :disabled="!canRegister"
          ></b-form-input>
          <b-form-invalid-feedback id="text-workplace_name-feedback">
            กรุณากรอกสถานที่ทำงาน
          </b-form-invalid-feedback>
        </div>
        <div class="py-2 col-12 col-md-6">
          <label for="text-position_name">ตำแหน่ง</label>
          <b-form-input
            type="text"
            id="text-position_name"
            v-model="form.position_name"
            :state="isSubmit ? (form.position_name ? null : false) : null"
            :disabled="!canRegister"
          ></b-form-input>
          <b-form-invalid-feedback id="text-position_name-feedback">
            กรุณากรอกตำแหน่ง
          </b-form-invalid-feedback>
        </div>
        <div class="py-2 col-12 col-md-6">
          <label for="text-affiliation">สังกัด</label>
          <b-form-input
            type="text"
            id="text-affiliation"
            v-model="form.affiliation_name"
            :state="isSubmit ? (form.affiliation_name ? null : false) : null"
            :disabled="!canRegister"
          ></b-form-input>
          <b-form-invalid-feedback id="text-affiliation-feedback">
            กรุณากรอกสังกัด
          </b-form-invalid-feedback>
        </div>
        <div class="py-2 col-12">
          <label for="text-address">ที่อยู่ปัจจุบัน</label>
          <b-form-textarea
            id="text-address"
            v-model="form.address"
            placeholder=""
            rows="3"
            :state="isSubmit ? (form.address ? null : false) : null"
            :disabled="!canRegister"
          ></b-form-textarea>
          <b-form-invalid-feedback id="text-address-feedback">
            กรุณากรอกที่อยู่ปัจจุบัน
          </b-form-invalid-feedback>
        </div>
        <div class="py-2 col-12 col-md-4">
          <label for="text-work-at">จังหวัด</label>
          <v-select
            :id="
              isSubmit
                ? form.province
                  ? 'provinces'
                  : 'rq_provinces'
                : 'provinces'
            "
            v-model="form.province"
            :options="provinces"
            @input="onInputProvince"
            :disabled="!canRegister"
          ></v-select>
          <small v-if="isSubmit && !form.province" class="text-danger">
            กรุณาเลือกจังหวัด
          </small>
        </div>
        <div class="py-2 col-12 col-md-4">
          <label for="amphures">อำเภอ</label>
          <v-select
            :id="
              isSubmit ? (form.amphur ? 'amphures' : 'rq_amphures') : 'amphures'
            "
            label="name_th"
            v-model="form.amphur"
            :options="amphures"
            @input="onInputAmphure"
            :disabled="!canRegister"
          ></v-select>
          <small v-if="isSubmit && !form.amphur" class="text-danger">
            กรุณาเลือกอำเภอ
          </small>
        </div>
        <div class="py-2 col-12 col-md-4">
          <label for="text-district">ตำบล</label>
          <v-select
            :id="
              isSubmit
                ? form.district
                  ? 'district'
                  : 'rq_district'
                : 'district'
            "
            label="name_th"
            v-model="form.district"
            :options="tombons"
            :disabled="!canRegister"
          ></v-select>
          <small v-if="isSubmit && !form.district" class="text-danger">
            กรุณาเลือกตำบล
          </small>
        </div>
        <div class="pt-5 col-12">
          <h5>หลักฐานประกอบการสมัคร</h5>
        </div>
        <div class="py-3 col-12 col-md-6">
          <label for="individual_img_url"
            >รูปถ่ายขนาด 1 นิ้ว จำนวน 1 รูป
          </label>

          <div class="mb-2">
            <small class="text-black-50 font-weight-bold"
              >*JPG, PNG ขนาดไม่เกิน 15MB (ขนาดแนะนำ {{ imageWidth }} x
              {{ imageHeight }} px)</small
            >
          </div>

          <b-form-file
            id="individual_img_url"
            v-model="individual_img_url"
            placeholder="กรุณาเลือกรูปภาพ..."
            drop-placeholder="วางรูปภาพลงที่นี่..."
            accept=".jpg, .png, .jpeg"
            :state="isSubmit ? (individual_img_url ? null : false) : null"
            @change="previewImages($event, 'individual')"
            :disabled="!canRegister"
          ></b-form-file>

          <small v-if="isSubmit && !individual_img_url" class="text-danger">
            กรุณาอัพโหลดรูปถ่าย
          </small>
          <carousel-teaching-image
            :removable="!disabled"
            :is-loading="disabled"
            class="mt-2"
            :col="8"
            v-model="file_individual_preview"
            @change="removedImages_individual"
          >
            <div class="d-flex align-items-center">
              <b-spinner
                v-if="isProcessing_individual"
                label="processing image..."
                class="ml-4 mb-4"
              >
              </b-spinner>
            </div>
          </carousel-teaching-image>
        </div>

        <div class="py-3 col-12 col-md-6">
          <label for="text-id_card_img_url">
            สำเนาบัตรประชาชน จำนวน 1 ฉบับ</label
          >

          <div class="mb-2">
            <small class="text-black-50 font-weight-bold"
              >*JPG, PNG ขนาดไม่เกิน 15MB (ขนาดแนะนำ {{ imageWidth }} x
              {{ imageHeight }} px)</small
            >
          </div>

          <b-form-file
            id="text-id_card_img_url"
            v-model="id_card_img_url"
            placeholder="กรุณาเลือกรูปภาพ..."
            drop-placeholder="วางรูปภาพลงที่นี่..."
            accept=".jpg, .png, .jpeg"
            :state="isSubmit ? (id_card_img_url ? null : false) : null"
            @change="previewImages($event, 'id_card')"
            :disabled="!canRegister"
          ></b-form-file>

          <small v-if="isSubmit && !id_card_img_url" class="text-danger">
            กรุณาอัพโหลดสำเนาบัตรประชาชน
          </small>

          <carousel-teaching-image
            :removable="!disabled"
            :is-loading="disabled"
            class="mt-2"
            :col="8"
            v-model="file_id_card_preview"
            @change="removedImages_id_card"
          >
            <div class="d-flex align-items-center">
              <b-spinner
                v-if="isProcessing_id_card"
                label="processing image..."
                class="ml-4 mb-4"
              >
              </b-spinner>
            </div>
          </carousel-teaching-image>
        </div>
        <div class="py-4 col-12" style="text-align: end">
          <b-button
            variant="primary"
            :disabled="!canRegister || checkingSubmit || otpTimeout > 0"
            @click="submitForm"
          >
            <b-spinner
              v-if="isSendingForm"
              small
              label="submiting..."
            ></b-spinner>
            <span v-else>สมัคร</span>
          </b-button>
        </div>
      </b-row>
    </page-content>

    <div v-if="isSrc" class="mt-1 mb-4 text-center">
      <router-link active-class="active" to="/sign-in" exact
        >กลับสู่หน้าเข้าสู่ระบบ</router-link
      >
    </div>

    <b-modal
      v-model="showModalOTP"
      id="otp-modal"
      centered
      hide-footer
      hide-header
    >
      <b-container>
        <b-row>
          <b-col>
            <h5 class="display-3 text-primary font-weight-bold">
              กรอกรหัส OTP
            </h5>

            <p class="text-black-50">
              รหัส OTP ได้ถูกส่งไปยังเบอร์โทรศัพท์หมายเลข
              {{ form.phone_number }}
            </p>

            <form>
              <div class="form-group">
                <label for="otp_code">รหัส OTP</label>
                <input
                  type="text"
                  class="form-control"
                  name="otp_code"
                  v-model="otp_code"
                />
              </div>

              <button
                class="btn btn-lg btn-primary btn-block"
                :disabled="checkingOTP"
                @click.prevent="validateOTP"
              >
                <b-spinner v-if="checkingOTP" label="Loading..."></b-spinner>
                <span v-else>ยืนยัน</span>
              </button>
            </form>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </b-container>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
// import PageTitle from "../../components/layout/PageTitle";
import { formatBuddhistBirthDate } from "../../helpers/datepicker-helper";
import { th } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import vSelect from "vue-select";
import { Auth, MRank, Registration } from "../../models";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import VueQr from "vue-qr";
import { generateQrCodePayment } from "../../helpers/template-pdf-transaction";
import GeneratePdf from "../../components/report/GeneratePdf.vue";
import CarouselTeachingImage from "../../components/carousel/TeachingImage";
import Compressor from "compressorjs";
import dataThailand from "./thailand.json";
import { chain, map, keys } from "lodash";

export default {
  mixins: [validationMixin],

  components: {
    PageContent,
    // PageTitle
    Datepicker,
    vSelect,
    VueQr,
    GeneratePdf,
    CarouselTeachingImage,
  },

  data() {
    return {
      logoSrc:
        "https://d10a8qjc50njp0.cloudfront.net/background-certificate/logo.png",
      size: 300,
      th: th,
      rank: null,
      provinces: [],
      amphures: [],
      district: [],
      tombons: [],
      isSubmit: false,
      isSendingForm: false,
      showModalOTP: false,
      canRegister: false,
      checkingSubmit: false,
      checkingOTP: false,
      otpTimeout: 0,
      optInterval: null,
      otp_code: null,
      token: "",
      individual_img_url: null,
      id_card_img_url: null,
      birthDate: null,
      isSrc: null,
      srcImgBase64: "",
      enableDownload: true,
      previewModal: false,
      format: "",
      disabled: false,
      isProcessing_id_card: false,
      isProcessing_individual: false,
      isFetchingRank: false,
      maxFiles: 1,
      dataThailands: {},
      form: {
        phone_number: "",
        first_name: "",
        last_name: "",
        bd_date: "",
        age: null,
        workplace_name: "",
        position_name: "",
        affiliation_name: "",
        address: "",
        province: "",
        amphur: "",
        district: "",
        email: "",
        individual_img_url: null,
        id_card_img_url: null,
        id_card: "",
        m_rank_id: null,
        user_profile_id: null,
      },
      file_individual_preview: [],
      file_id_card_preview: [],
      imageWidth: 768,
      imageHeight: 1024,
    };
  },

  validations: {
    form: {
      phone_number: { required },
      first_name: { required },
      last_name: { required },
      bd_date: { required },
      id_card: { required },
      workplace_name: { required },
      position_name: { required },
      affiliation_name: { required },
      address: { required },
      province: { required },
      amphur: { required },
      district: { required },
      email: { required },
      m_rank_id: { required },
    },
    individual_img_url: { required },
    id_card_img_url: { required },
  },

  watch: {
    otpTimeout: "onOtpTimeoutChanged",

    birthDate: {
      handler: "onBirthDateChanged",
    },

    rank: {
      handler: "onRankChanged",
    },
  },

  computed: {
    dateNow() {
      const tmpDate = new Date();

      let monthNames = [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ];

      let day = tmpDate.getDate();

      let monthIndex = tmpDate.getMonth();
      let monthName = monthNames[monthIndex];

      let year = tmpDate.getFullYear() + 543;

      return `วันที่ ${day} เดือน ${monthName} พ.ศ. ${year}`;
    },

    ranks() {
      return MRank.all().map((record) => ({
        id: record.id,
        label: record.m_rank_name,
      }));
    },
  },

  methods: {
    formatBuddhistBirthDate,

    async previewImages(event, key = "") {
      let { maxFiles } = this;

      if (maxFiles <= 0) {
        return;
      }

      const self = this;

      var input = event.target;

      const maxAllowedSize = 15 * 1024 * 1024;

      Array.from(input.files).forEach((file) => {
        if (file.size <= maxAllowedSize) {
          this[`file_${key}_preview`].splice(0, 1);

          this[`isProcessing_${key}`] = true;

          new Compressor(file, {
            quality: 0.8,

            maxWidth: this.imageWidth,

            maxHeight: this.imageHeight,

            success(result) {
              var reader = new FileReader();

              reader.onload = async (e) => {
                self[`file_${key}_preview`].push({
                  file: result,
                  raw: e.target.result,
                });

                self[`isProcessing_${key}`] = false;
              };

              reader.readAsDataURL(result);
            },
            error(err) {
              console.log(err.message);
              this.$toast.error("ไม่สามารถอัพโหลดไฟล์ได้ กรุณาลองใหม่อีกครั้ง");
            },
          });
        } else {
          this.$toast.error(
            "ไม่สามารถอัพโหลดไฟล์ขนาดใหญ่เกิน 15 MB ได้ กรุณาลองใหม่อีกครั้ง"
          );
        }
      });

      // input.value = "";
    },

    onBirthDateChanged(d) {
      let date = new Date(d);
      let ageDifMs = Date.now() - date.getTime();
      let ageDate = new Date(ageDifMs);
      this.form.age = Math.abs(ageDate.getUTCFullYear() - 1970);

      const dObj = new Date(date.getTime());
      dObj.setFullYear(dObj.getFullYear() + 543);
      dObj.setHours(0);
      dObj.setMinutes(0);
      dObj.setSeconds(0);

      this.$set(this.form, "bd_date", dObj.toISOString());
    },

    async submitForm() {
      this.isSubmit = true;

      this.$v.$touch();
      if (this.$v.$invalid) {
        return this.$toast.error(`กรุณากรอกข้อมูลให้ครบถ้วน`);
      }

      this.checkingSubmit = true;

      let { phone_number } = this.form;

      phone_number = phone_number.replace(/-/g, "");

      try {
        const token = await Auth.api().getOTP(phone_number);

        this.token = token;

        this.showModalOTP = true;
      } catch (message) {
        this.$toast.error(message);
      } finally {
        this.checkingSubmit = false;
      }
    },

    async sendForm() {
      this.isSendingForm = true;
      this.canRegister = false;
      try {
        const presignPromise_1 = await Registration.api().getUploadUrl({
          phone_number: this.form.phone_number.replace(/-/g, ""),
          file_name: this.individual_img_url.name,
          contentType: this.individual_img_url.type,
        });

        if (presignPromise_1 && presignPromise_1.response) {
          const { url, fullUrl } = presignPromise_1.response.data;

          if (url) {
            await axios.put(url, this.individual_img_url);
          }
          this.form.individual_img_url = fullUrl;
        }

        const presignPromise_2 = await Registration.api().getUploadUrl({
          phone_number: this.form.phone_number.replace(/-/g, ""),
          file_name: this.id_card_img_url.name,
          contentType: this.id_card_img_url.type,
        });

        if (presignPromise_2 && presignPromise_2.response) {
          const { url, fullUrl } = presignPromise_2.response.data;

          if (url) {
            await axios.put(url, this.id_card_img_url);
          }
          this.form.id_card_img_url = fullUrl;
        }

        const { response } = await Registration.api().create({
          ...this.form,
          phone_number: this.form.phone_number.replace(/-/g, ""),
          id_card: this.form.id_card.replace(/-/g, ""),
        });

        if (response && response.data && response.data.data) {
          this.$toast.success("สมัครสมาชิกครูแดร์สำเร็จเรียบร้อย");

          this.isSrc =
            window.location.protocol +
            "//" +
            window.location.host +
            `/registers/detail?code=${response.data.data.registration_code}`;
        }
      } catch (message) {
        this.$toast.error(message);
      } finally {
        this.isSendingForm = false;
      }
    },

    qrCallback(dataUrl) {
      this.srcImgBase64 = dataUrl;
    },

    async downLoadPdfQrcode() {
      let item = {
        title: `การสมัครสมาชิกชมรมแดร์ประเทศไทยสำเร็จเรียบร้อย`,
        srcImgBase64: this.srcImgBase64,
      };
      this.format = await generateQrCodePayment(item);

      if (this.$refs.pdf) {
        const { $refs } = this.$refs.pdf;

        $refs.html2Pdf.generatePdf();
      }
    },

    setOtpTimeout() {
      this.otpTimeout = 30;
    },

    onOtpTimeoutChanged(v) {
      if (v > 0 && !this.optInterval) {
        this.optInterval = setInterval(() => {
          this.otpTimeout -= 1;
        }, 1000);
      } else {
        if (v === 0) {
          clearInterval(this.optInterval);
          this.optInterval = null;
        }
      }
    },

    async validateOTP() {
      this.checkingOTP = true;
      try {
        if (!this.otp_code.length) {
          throw "กรุณากรอกรหัส OTP";
        }

        const valid = await Auth.api().validateOTP(this.token, this.otp_code);

        if (valid) {
          this.sendForm();
          this.showModalOTP = false;
          this.$toast.success("ยืนยันเบอร์มือถือสำเร็จ");

          // setTimeout(() => {
          //   location.href =
          //     decodeURIComponent(this.$route.query.redirectUrl) || "/";
          // }, 1000);
        }
      } catch (message) {
        console.error();
        message;
        this.$toast.error(message);
      } finally {
        this.checkingOTP = false;
      }
    },

    chkDigitPid(p_iPID) {
      var total = 0;
      var iPID;
      var chk;
      var Validchk;
      iPID = p_iPID.replace(/-/g, "");
      Validchk = iPID.substr(12, 1);
      var j = 0;
      var pidcut;
      for (var n = 0; n < 12; n++) {
        pidcut = parseInt(iPID.substr(j, 1));
        total = total + pidcut * (13 - n);
        j++;
      }

      chk = 11 - (total % 11);

      if (chk == 10) {
        chk = 0;
      } else if (chk == 11) {
        chk = 1;
      }
      if (chk == Validchk) {
        // alert("ระบุหมายเลขประจำตัวประชาชนถูกต้อง");
        return true;
      } else {
        // alert("ระบุหมายเลขประจำตัวประชาชนไม่ถูกต้อง");
        return false;
      }
    },

    onInputIdCard() {
      const count_id_card = this.form.id_card.replace(/-/g, "");
      if (!this.form.id_card || count_id_card.length != 13) {
        this.canRegister = false;
      }
    },

    async checkAccessRegister() {
      this.isSubmit = false;
      let check_id_card = this.chkDigitPid(this.form.id_card);

      const count_id_card = this.form.id_card.replace(/-/g, "");

      if (!this.form.id_card || count_id_card.length != 13 || !check_id_card) {
        this.canRegister = false;
        return this.$toast.error("ระบุหมายเลขประจำตัวประชาชนไม่ถูกต้อง");
      }

      try {
        const { response } = await Registration.api().checkIdCard({
          id_card: this.form.id_card.replace(/-/g, ""),
        });

        if (response && response.data && response.data.valid) {
          this.canRegister = true;
          if (response.data.data) {
            const { data } = response.data;
            this.form.m_rank_id = data.m_rank ? data.m_rank.id : null;
            this.rank = data.m_rank ? data.m_rank.m_rank_name : "";
            this.form.first_name = data.first_name ? data.first_name : "";
            this.form.last_name = data.last_name ? data.last_name : "";
            this.birthDate = data.bd_date
              ? new Date(data.bd_date).setFullYear(
                  new Date(data.bd_date).getFullYear() - 543
                )
              : null;
            this.form.phone_number = data.phone_number ? data.phone_number : "";
            this.form.email = data.email ? data.email : "";
            this.form.affiliation_name = data.m_division
              ? data.m_division.m_division_name
              : "";
            this.form.workplace_name = data.m_station
              ? data.m_station.m_station_name
              : "";
            this.form.position_name = data.position_name
              ? data.position_name
              : "";
            this.form.address = data.address ? data.address : "";
            this.form.user_profile_id = data ? data.id : null;
          } else {
            this.form.m_rank_id = null;
            this.rank = "";
            this.form.first_name = "";
            this.form.last_name = "";
            this.birthDate = null;
            this.form.phone_number = "";
            this.form.email = "";
            this.form.affiliation_name = "";
            this.form.workplace_name = "";
            this.form.position_name = "";
            this.form.address = "";
            this.form.user_profile_id = null;
          }
          this.$toast.success(
            "หมายเลขบัตรประชาชนนี้ สามารถใช้สมัครสมาชิกครูแดร์ได้"
          );
        } else {
          this.canRegister = false;
          return this.$toast.error(
            "หมายเลขบัตรประชาชนนี้ อยู่ในระบบเรียบร้อยแล้ว"
          );
        }
      } catch (error) {
        this.canRegister = false;
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          return this.$toast.error(error.response.data.message);
        } else {
          return this.$toast.error(
            "ไม่สามารถใช้หมายเลขบัตรประชาชนนี้สมัครสมาชิกได้ กรุณาลองใหม่อีกรครั้ง"
          );
        }
      }
    },

    async getProvinces() {
      // console.log(
      //   chain(dataThailand)
      //     .groupBy("province")
      //     .mapValues((values) => chain(values).groupBy("amphoe").value())
      //     .value()
      // );

      this.dataThailands = chain(dataThailand)
        .groupBy("province")
        .mapValues((values) => chain(values).groupBy("amphoe").value())
        .value();

      // let { data } = await axios.get(
      //   "https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province_with_amphure_tombon.json"
      // );

      if (this.dataThailands) {
        this.provinces = keys(this.dataThailands);
      }
    },

    onInputProvince(value) {
      this.form.province = value;
      this.form.amphur = "";
      this.form.district = "";
      this.district = this.dataThailands[value];
      this.amphures = keys(this.dataThailands[value]);
    },

    onInputAmphure(value) {
      this.form.amphur = value;
      this.form.district = "";
      this.tombons = map(this.district[value], "district");
    },

    removedImages_individual() {
      this.individual_img_url = null;
    },
    removedImages_id_card() {
      this.id_card_img_url = null;
    },

    async fetchRanks() {
      this.isFetchingRank = true;

      try {
        await MRank.api().findAll();
      } catch (error) {
        console.log(error);
        this.$toast.error("ไม่สามารถโหลดข้อมูลตำแหน่งได้ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.isFetchingRank = false;
      }
    },

    onRankChanged(rank = null) {
      let id = this.form.m_rank_id ? this.form.m_rank_id : null;

      if (rank && rank.id) {
        id = rank.id;
      }

      this.$set(this.form, "m_rank_id", id);
    },
  },

  async mounted() {
    await Promise.all([this.fetchRanks(), this.getProvinces()]);
  },
};
</script>

<style lang="scss" scoped>
/deep/ .required-date {
  border-color: #f83245 !important;
}

.form-control.is-invalid {
  background-image: none !important;
}

/deep/ #rq_rank.v-select .vs__dropdown-toggle {
  border-color: #f83245 !important;
}
/deep/ #rq_provinces.v-select .vs__dropdown-toggle {
  border-color: #f83245 !important;
}
/deep/ #rq_amphures.v-select .vs__dropdown-toggle {
  border-color: #f83245 !important;
}
/deep/ #rq_district.v-select .vs__dropdown-toggle {
  border-color: #f83245 !important;
}
</style>
